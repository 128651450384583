<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="one text-center"
      space="56"
    >
      <base-icon class="mb-8">
        mdi-instagram
      </base-icon>

      <base-section-heading
        color="transparent"
        title="Social Media"
      >
        Follow us on instagram to keep up with the latest Y-Squared News.
      </base-section-heading>

      <a
        class="d-inline-block mb-8"
        href="https://www.instagram.com/ysquaredteam/"
        style="text-decoration: none;"
        target="_blank"
      >
        https://www.instagram.com/ysquaredteam/
      </a>
      <br>

      <v-btn
        class="font-weight-bold"
        color="white"
        href="https://www.instagram.com/ysquaredteam/"
        light
        min-width="168"
        target="_blank"
        x-large
      >
        Follow Us
      </v-btn>
    </base-section>
  </v-theme-provider>
</template>

<style scoped>
.one {
  background: linear-gradient(
    89deg,
    rgba(110, 0, 107, 0.959),
    rgb(0, 65, 102),
    rgb(105, 0, 23)
  );
  background-size: 800% 800%;
  animation: AnimationName 20s ease infinite;
  justify-content: center;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
</style>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
